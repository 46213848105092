import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pb } from "../pb";

export const Project = () => {
  const tags = ["Brand", "Website", "Marketing"];

  // Get the 'name' parameter from the URL
  const { name } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [photos, setPhotos] = useState([]); // State to store photos
  const [error, setError] = useState(null);
  const [diffData, setDiffData] = useState();
  const [loading, setLoading] = useState(true);

  // Function to fetch the project based on the name parameter
  const fetchProjectByName = async () => {
    try {
      // Fetch the project data where the seo_url (or name field) matches the name from the URL
      const result = await pb
        .collection("project")
        .getFirstListItem(`seo_url = "${name}"`, {
          expand: "cat, client, industry",
        });

      console.log(result);

      // Set the project data in state
      setProjectData(result);

      // After fetching the project, fetch the photos for the project
      fetchProjectPhotos(result.id);
    } catch (error) {
      // Handle any errors
      setError("Failed to fetch project data");
    }
  };

  // Function to fetch photos related to the project
  const fetchProjectPhotos = async (projectId) => {
    try {
      // Fetch photos where the 'project' field matches the project ID
      const photoResults = await pb.collection("photos").getFullList({
        filter: `project = "${projectId}"`,
      });


      // Set the photos in state
      setPhotos(photoResults);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching project photos:", error);
      setError("Failed to fetch project photos");
    }
  };

  // Fetch project data when the component mounts or when 'name' changes
  useEffect(() => {
    if (name) {
      fetchProjectByName();
    }
  }, [name]);

 if (loading) {
  return (
    <>
    <div className=" h-screen inset-0 absolute w-full z-50 bg-white flex flex-wrap justify-center content-center">
    <span class="loading loading-dots  loading-lg text-blue-600"></span>

    </div>
    </>
  )
  
 }

  return (
    <>
      {projectData ? (
        <div className="bg-white">
          <div className="relative w-full">
              <img
                className="w-full  hidden md:block"
                src={`${process.env.REACT_APP_FILES_URL}${projectData.collectionId}/${projectData.id}/${projectData.grid_banner}`}
                alt=""
              />

              <img
                className="  block md:hidden"
                src={`${process.env.REACT_APP_FILES_URL}${projectData.collectionId}/${projectData.id}/${projectData.mobile_banner}`}
                alt=""
              />
            </div>
          <div className="container mx-auto max-w-7xl  pt-5 pb-5 md:pt-10 md:pb-10 px-5 md:px-0">
            

            <div className="">
              {/* name */}
              <div className="mt-10 py-10 border-b ">
                <p className=" text-5xl md:text-7xl  font-light text-neutral">
                  {projectData.title}
                </p>
                <div className="mt-4 space-x-4  ">
                  {projectData.expand.cat.map((tag) => (
                    <span className="px-4 py-1.5 text-slate-700 rounded-full bg-slate-200/60 hover:bg-black hover:text-white">
                      {tag.name}
                    </span>
                  ))}
                </div>
              </div>

              {/* description */}
              <div className="py-10 md:flex md:gap-4">
                <p className="font-extralight md:w-6/12 sm:w-full w-full text-lg">
                  {projectData.short_desc}
                </p>
                <div className="w-full md:w-6/12 sm:w-full   flex  flex-row  md:justify-end   justify-between mt-8 md:mt-0 space-x-5">
                  {projectData.expand.client && (
                    <div className="px-4 ">
                      <p className="text-sm  font-extralight text-neutral">
                        Client
                      </p>
                      <p className="text-lg">
                        {projectData.expand.client.name}
                      </p>
                    </div>
                  )}
                  <div className="px-4 ">
                    <p className="text-sm  font-extralight text-neutral">
                      Industry
                    </p>
                    <p className="text-lg">
                      {projectData.expand.industry.name}
                    </p>
                  </div>
                  <div className="px-4">
                    <p className="text-sm  font-extralight text-neutral">
                      Year
                    </p>
                    <p className="text-lg">
                      {new Date(projectData.project_date).getFullYear()}
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
                
                {photos &&
                  photos.map((photo) => (
                    <div
                      className={`${
                        photo.width === "full" ? "md:lg:xl:col-span-2" : ""
                      }`}
                    >
                      <img
                        className="rounded-xl md:rounded-2xl w-full"
                        src={`${process.env.REACT_APP_FILES_URL}${photo.collectionId}/${photo.id}/${photo.image}`}
                        alt={photo.alt}
                      />
                    </div>
                  ))}
              </div>

              {/* diff */}
              {diffData && (
                <div>
                  <div className="diff aspect-[16/9] rounded-xl md:rounded-2xl overflow-hidden mt-10 bg-slate-50  ">
                    <div className="diff-item-1 ">
                      <img
                        className=" "
                        src="https://made-byshape.transforms.svdcdn.com/production/uploads/images/Projects/Studio-25/window.jpg?w=2500&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1631804716&s=4b48cde02af80f2b53435103198dd145"
                        alt=""
                      />
                    </div>
                    <div className="diff-item-2">
                      <img
                        className=" "
                        src="https://made-byshape.transforms.svdcdn.com/production/uploads/images/Projects/Studio-25/bottle.jpg?w=2500&q=80&fm=webp&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.5&dm=1631804709&s=a31b8cd940d7f54d9116b8fe9a57b15a"
                        alt=""
                      />
                    </div>
                    <div className="diff-resizer"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>Loading....</p>
      )}
    </>
  );
};
