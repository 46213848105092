import React, { useState , useEffect} from "react";

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import { pb } from "../pb";
import { Loader } from "../comp/Loader";
 
export const Work = () => {
  const [cat, setCat] = useState([]);
  const [catSelect, setCatSelect] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);


  const fetchCatData = async () => {
    try {
      const records = await pb.collection('cat').getFullList({ sort: 'name'}); // Fetch all records from the 'cat' collection
      setCat(records);
      getProjectList()
    } catch (error) {
      console.error('Error fetching cat data:', error);
    }
  };

  // Function to fetch project list
  const getProjectList = async (selectedCat = null) => {
    try {
      const filter = selectedCat ? `cat?~"${selectedCat}"` : '';  
      const resultList = await pb.collection('project').getFullList({sort: 'number', expand :  'cat, client, industry', filter});
  
      
      setProjectList(resultList);
      setLoading(false)
    } catch (error) {
      // Handle error and store it in state
      console.error('Error fetching project list:', error);
      setError('Failed to fetch project list');
    }
  };

// Fetch the project list again whenever catSelect changes
  useEffect(() => {
    setLoading(true);
    getProjectList(catSelect); // Pass the selected category to filter projects
  }, [catSelect]);

   // useEffect to call getProjectList on component mount
   useEffect(() => {
    
    fetchCatData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Our Work | Portfolio of Graphwizards</title>
        <meta
          name="description"
          content="Explore the creative work by Graphwizards. Check out our portfolio of graphic and web design projects from various industries."
        />
        <meta
          name="keywords"
          content="Graphwizards portfolio, web design projects, graphic design portfolio, Patiala design agency, Punjab creative work"
        />
        <link rel="canonical" href="https://graphwizards.in/portfolio" />
      </Helmet>

     {loading  ? <Loader/> :  <div className="bg-white">
        <div className="mx-auto container py-20 md:py-32 px-5 md:px-0">
          <h2 className="text-primary font-semibold text-lg">Our Work</h2>
          <ul className="flex flex-wrap  space-y-5 md:space-y-6 items-end text-3xl md:text-3xl font-medium text-black/30">
          <li
            className={`${
              catSelect===  null ? 'text-black' : 'hover:text-black'
            } mr-6 md:mr-8 cursor-pointer`}
          
            onClick={() => setCatSelect(null)} // Set catSelect to item.id on click
          >
            All {/* Assuming 'name' is the field you want to display */}
          </li>
          {cat &&
          
        cat.map((item) => (
          <li
            className={`${
              item.id === catSelect ? 'text-black' : 'hover:text-black'
            } mr-6 md:mr-8 cursor-pointer`}
            key={item.id} // Use item.id as the key
            onClick={() => setCatSelect(item.id)} // Set catSelect to item.id on click
          >
            {item.name} {/* Assuming 'name' is the field you want to display */}
          </li>
        ))}
          </ul>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 pb-32  container px-5 md:px-0 mx-auto gap-10">
       

          
          {projectList &&
            projectList.map((item, index) => (
              <Link to={'project/'+item.seo_url}>
              <div key={index} className="group rounded-xl  ">
                <div className="rounded-lg relative overflow-hidden ">
                  <div className="absolute">
                    <div className="relative bg-white pr-6    rounded-br-[1.5rem] py-4 space-x-3 transform -translate-y-full group-hover:-translate-y-0  duration-150">
                      {/* bottom svg */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className=" fill-white h-6 w-6 absolute left-0  -bottom-6"
                        viewBox="0 0 33.131 33.234"
                      >
                        <path
                          id="Subtraction_1"
                          data-name="Subtraction 1"
                          d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                          transform="translate(0.001)"
                        />
                      </svg>

                      {/* right svg */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="md:w-6 md:h-6 absolute md:-right-6 h-4 w-4 -right-4  top-0 fill-white"
                        viewBox="0 0 33.131 33.234"
                      >
                        <path
                          id="Subtraction_1"
                          data-name="Subtraction 1"
                          d="M0,33.234H0V0H33.13A33.474,33.474,0,0,0,0,33.234Z"
                          transform="translate(0.001)"
                        />
                      </svg>

                      {item.expand.cat &&
                        item.expand.cat.map((item) => (
                          <span className="px-4 py-1.5 text-slate-700 rounded-full bg-slate-200/60 hover:bg-black hover:text-white text-sm">
                            {item.name}
                          </span>
                        ))}
                    </div>
                  </div>

                  <div className="absolute bottom-0 right-0 duration-150 transform translate-y-full group-hover:translate-y-0">
                    <div className="pl-3 pt-3 rounded-tl-xl bg-white shadow-xl  ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="size-7"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                        />
                      </svg>

                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 86.57 86.18"
                        className="absolute h-4 w-4 bottom-0 -left-4 fill-white"
                      >
                        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                      </svg>

                      <svg
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 86.57 86.18"
                        className="absolute h-4 w-4 -top-4 right-0 fill-white"
                      >
                        <path d="M86.57,0h0v86.18H0c22.85-.21,44.71-9.34,60.93-25.44,16.25-16.05,25.47-37.9,25.64-60.74Z" />
                      </svg>
                    </div>
                  </div>
                  <img
                     src={`${process.env.REACT_APP_FILES_URL}${item.collectionId}/${item.id}/${item.banner}`} 
                     alt={item.name} 
                    className="w-full rounded-[1rem]"
                     
                  />
                </div>
                <div className="px-4 py-3">
                  <p className="text-xl font-light mt-3 group-hover:underline">
                    {item.title}
                  </p>
                  <p className="text-semibold text-lg text-primary">
                    {item.expand.client && item.expand.client.name}
                  </p>
                </div>
              </div>
              </Link>
            ))}

            
        </div>
      </div>}

 

   
    </>
  );
};



const NoProject = () => {
  return(
    <div className="flex justify-center  py-10 border rounded-xl col-span-3">
    <div className="text-center">
    <h2  >
       Looks like we're waiting for your next big idea!
     </h2>
     <p className="text-lg text-gray-500 mt-4">
       Our portfolio is just waiting for a project like yours. Let's create something amazing together.
     </p>
     <button 
       className="mt-6 bg-primary text-white py-3 px-6 rounded-full hover:bg-primary-dark transition duration-300"
       onClick={() => { /* You can add a link or action to redirect to a contact or project initiation page */ }}
     >
       Let’s Start This Project
     </button>
    </div>
   </div>
  )
}