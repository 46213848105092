import React, {useEffect} from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { Start } from "./routes/Start";
import { Blogs } from "./routes/Blogs";
import { Services } from "./routes/Services";
import { Work } from "./routes/Work";
import { Home } from "./routes/Home";
import { Contact } from "./routes/Contact";
import { Navbar } from "./comp/Navbar";


import {Project} from './routes/Project'
import { Footer } from "./comp/Footer";


 

export const UserLayout = () => {
  const location = useLocation();
  const {pathname}  = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [pathname]); // Effect runs whenever the pathname changes

  return (
    <>
    <Navbar/>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio/*" element={<Work />} />
        <Route path="/portfolio/project/:name" element={<Project />} />
        

        <Route path="*" element={<Navigate to="/error404" replace />} />
        

        <Route path="start-project" element={<Start />} />
      </Routes>

     <Footer/>
    </>
  );
};
